@import "/styles/variables.scss";

.background {
  background-color: $white;
  padding-top: 50px;
  position: relative;
  z-index: 1;
}

.heading {
  color: #15161e;
  font-size: rem(20px);
  font-weight: $fontWeightBold;
  margin-bottom: 25px;
  letter-spacing: -0.035em;
  line-height: rem(21px);
}

.disclosure {
  font-weight: 500;
  font-size: rem(11px);
  line-height: rem(13px);
  letter-spacing: -0.02em;
  padding: 30px 0px;

  a[href] {
    color: $pink;
    text-decoration: underline;
  }
}

.expandLocationsModalLink {
  background: transparent;
  border: 0px;
  color: $pink !important;
  cursor: pointer;
  padding: 0px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

@include media-breakpoint-up(md) {
  .background {
    padding-bottom: 30px;
    padding-top: 60px;
  }

  .heading {
    margin-bottom: 15px;
  }
}

@include media-breakpoint-down(md) {
  .background {
    --bs-gutter-x: 15px;
  }

  .heading {
    padding-left: 25px;
    padding-right: 25px;
  }

  .vehicleCol {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
