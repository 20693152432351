@import "/styles/variables.scss";

.messageBanner {
  background: $black;
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.0675951),
    0px 5px 20px -9px rgba(0, 0, 0, 0.148353);
  border-radius: 5px;
  color: $white;
  display: flex;
  align-items: center;
  padding: 15px 20px 15px 15px;
  font-weight: 500;
  font-size: rem(13px);
  line-height: rem(15px);
  letter-spacing: -0.02em;
  margin-top: 10px;
  position: sticky;
  bottom: 30px;
  visibility: hidden;

  &.visibleBanner {
    visibility: visible;
  }
}

.bannerIcon {
  flex-shrink: 0;
  margin-right: 15px;
}

.bannerCloseBtn {
  align-self: flex-start;
  appearance: none;
  border: 0px;
  background-color: transparent;
  display: flex;
  margin-left: 15px;
  padding: 0px;
}

@include media-breakpoint-up(md) {
  .messageBanner {
    font-size: rem(15px);
    line-height: rem(22px);
    justify-content: space-between;
    margin-top: 30px;
    padding: 19px 10px 19px 30px;
  }
}
