@use "sass:math";
@import "/styles/variables.scss";

.card {
  background: no-repeat linear-gradient(#f5f5f5 100%, transparent 0%);
  height: 100%;
  padding-bottom: 25px;
  padding-top: 25px;
  position: relative;
  transition: background 0.2s linear;
}

.vehicleDetails,
.footer {
  padding-left: 25px;
  padding-right: 25px;
}

.vehicleDetails {
  margin-bottom: 30px;
}

.vehicleMake,
.vehicleModel,
.vehicleSubModel {
  font-weight: $fontWeightBold;
  letter-spacing: -0.035em;
}

.vehicleMake {
  font-size: rem(20px);
  line-height: rem(21px);
}

.vehicleModel {
  font-size: rem(42px);
  line-height: rem(44px);
}

.rotatingImage {
  display: none;
  object-fit: contain;
}

.footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.priceText {
  font-size: rem(13px);
  font-weight: $fontWeightMedium;
  letter-spacing: -0.02em;
  line-height: rem(15px);
}

.price {
  font-weight: inherit;
}

.cta {
  border-radius: 25px;
  font-weight: $fontWeightBold;
  font-size: rem(15px);
  line-height: rem(22px);
  min-width: 132px;
}

.card:not(:hover) {
  .cta {
    background-color: $citrine;
    border-color: #e6f200;
  }
}

.card_0:hover {
  background: no-repeat linear-gradient(#ebf750 100%, transparent 0%);
}

.card_1:hover {
  background: no-repeat linear-gradient($lightBlue 100%, transparent 0%);
}

@include media-breakpoint-up(md) {
  .image,
  .rotatingImage {
    padding-top: 60px;
  }

  .card {
    background-position-y: 135px;
    padding-bottom: 30px;

    &:hover {
      background-position-y: 75px;

      .image {
        display: none;
      }

      .rotatingImage {
        display: block;
        padding-bottom: 30px;
        padding-top: 0px;
      }

      .footer {
        padding-top: 30px;
      }
    }
  }

  .vehicleModel {
    font-size: rem(60px);
    line-height: rem(60px);
  }

  .vehicleSubModel {
    color: $legallyGray;
    font-size: rem(38px);
    line-height: rem(44px);
  }

  .price {
    font-weight: $fontWeightBold;
  }
}

@include media-breakpoint-up(lg) {
  .vehicleDetails,
  .footer {
    padding-left: 60px;
    padding-right: 60px;
  }

  .priceText {
    font-size: rem(20);
    letter-spacing: -0.035em;
    line-height: rem(21px);
  }

  .cta {
    min-width: 200px;
  }
}
