@import "/styles/variables.scss";

.messageContent {
  padding-bottom: 6.25rem;
  padding-top: 6.25rem;
}

.heading {
  font-size: rem(42px);
  font-weight: 700;
  letter-spacing: -0.035em;
  line-height: rem(44px);
  margin-bottom: 25px;
}

.subheading {
  font-size: rem(15px);
  font-weight: 400;
  line-height: rem(22px);
  margin-bottom: 25px;
}
