@import "/styles/variables.scss";

.contactFormSection {
  padding-bottom: 30px;
  padding-top: 30px;
}

.formHeading,
.formSuccessMessage {
  font-size: rem(15px);
  font-weight: $fontWeightBold;
  letter-spacing: -0.01em;
  line-height: rem(18px);
}

.textContent {
  margin-bottom: 1.25rem;
}

.disclosure {
  color: $legallyGray;
  font-size: rem(11px);
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: rem(13px);
  margin-top: 5px;
  margin-bottom: 0px;
}

.disclosure > * > a {
  color: $pink;
  text-decoration: underline;
}

.formSuccessMessage {
  text-align: center;
}

.emailInput {
  background-color: #f6f6f6;
  border: none;
  border-radius: 5px;
  padding: 0.625rem 1.125rem;
}

.submitBtn {
  align-items: center;
  color: $black;
  display: flex;
  font-size: rem(15px);
  font-weight: $fontWeightMedium;
  padding: 0.625rem 1.5rem;
}

.privacy {
  margin-top: 0.5rem;
  letter-spacing: $letterSpacingTight;
  a {
    color: $pink;
    text-decoration: underline;
  }
}

@include media-breakpoint-up(lg) {
  .contactFormSection {
    padding-bottom: 60px;
    padding-top: 60px;
  }

  .formHeading,
  .formSuccessMessage {
    font-size: rem(25px);
    line-height: rem(25px);
    letter-spacing: $letterSpacing_3xs;
    margin-bottom: 0px;
  }
}
