@import "/styles/variables.scss";

.label {
  font-weight: 500;
  font-size: rem(13px);
  line-height: rem(15px);
  letter-spacing: -0.02em;
  margin-bottom: 8px;
  width: 100%;
}

.input {
  padding: 10px 0px 10px 15px;
  height: 40px;
  border: 1px solid rgba(21, 22, 30, 0.2);
  border-radius: 5px;
  width: 100%;
}

.formGroup {
  margin-bottom: 25px;
}

.submitButton {
  appearance: none;
  background: $pink;
  border: 1px solid $pink;
  border-radius: 25px;
  font-weight: 500;
  font-size: rem(18px);
  line-height: rem(23px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  color: $purple;
  width: 100%;

  &:disabled {
    opacity: 0.4;
  }
}

.formError {
  color: $red;
  font-weight: 500;
  font-size: rem(14px);
  margin-top: 0.5rem;
  text-align: center;
}

.disclosure {
  color: $legallyGray;
  font-size: rem(11px);
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: rem(13px);
  margin-top: 30px;
  margin-bottom: 30px;
}
