@import "/styles/variables.scss";

.section {
  --bs-gutter-x: 15px;
  background-color: $white;
  padding-bottom: 15px;
  padding-top: 15px;
  position: relative;
  z-index: 1;
}

.card {
  position: relative;
}

.background {
  background: var(--greener-section-mobile-bg-image) no-repeat center center
    white;
  background-size: 100% 100%;
  height: rem(615px);
}

.content {
  position: absolute;
  top: 2rem;
  left: 15px;
  width: calc(100% - 30px);
  padding: 2rem 1.7rem;
  font-size: rem(15px);
  line-height: rem(22px);
  .title {
    color: $black;
    font-size: rem(42px);
    line-height: rem(44px);
    font-weight: $fontWeightBold;
    letter-spacing: $letterSpacing_3xs;
    margin-bottom: rem(25px);
  }
}

.car {
  position: absolute;
  bottom: 40px;
  left: 13%;
  z-index: 3;
  padding-right: rem(20px);
  .imageWrap {
    position: relative;
    z-index: 2;
  }
  .blob {
    background: transparent;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0.8, 1);
    filter: blur(5px);
    margin: 10px;
    height: 20px;
    width: 20px;
    transform: scale(1);
    position: absolute;
    top: 0%;
    left: 1.8%;
    z-index: 0;
    animation: pulse-green 4s infinite;
    z-index: 1;
  }
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
    opacity: 1;
  }

  30% {
    opacity: 0.4;
  }

  70% {
    box-shadow: 0 0 0 30px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
    opacity: 0.1;
  }
}

@media (min-width: 400px) {
  .car {
    .blob {
      left: 2.5%;
    }
  }
}

@media (min-width: 480px) {
  .car {
    .blob {
      left: 4.25%;
      top: 3%;
    }
  }
}

@media (min-width: 560px) {
  .car {
    left: 12%;
  }
}

@media (min-width: 660px) {
  .car {
    .blob {
      left: 4.85%;
      top: 4%;
    }
  }
}

@media (min-width: 700px) {
  .car {
    padding-right: 7rem;
    .blob {
      left: 3.8%;
      top: 4%;
    }
  }
}

@include media-breakpoint-up(md) {
  .background {
    height: rem(700px);
  }
  .content {
    top: 2rem;
    left: auto;
    right: 2rem;
    width: 47%;
    padding: 1rem 1.5rem;
    margin: 2rem 1rem 0;
    .title {
      width: 265px;
    }
  }

  .car {
    bottom: 20px;
    .blob {
      left: 4.37%;
      top: 5%;
    }
  }
}

@include media-breakpoint-up(lg) {
  .section {
    --bs-gutter-x: 40px;
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .background {
    background: var(--greener-section-bg-image) no-repeat left center white;
    background-size: 100% 100%;
  }
  .content {
    width: 36%;
    padding: 0;
    margin: rem(70px);
    .title {
      max-width: 260px;
      font-size: $fontSize_7xl;
      line-height: $fontSize_7xl;
    }
  }
  .car {
    left: 4.5%;
    padding-right: 13.25rem;
    bottom: 40px;
    .blob {
      left: 5%;
      top: 7%;
    }
  }
}

@media (min-width: 1100px) {
  .car {
    left: 4%;
    .blob {
      left: 5%;
    }
  }
}

@media (min-width: 1140px) {
  .car {
    left: 3.5%;
    .blob {
      width: 30px;
      height: 30px;
      left: 5%;
    }
  }
}

@include media-breakpoint-up(xl) {
  .background {
    height: rem(750px);
  }
  .content {
    width: 29%;
  }
}

@media (min-width: 1300px) {
  .car {
    left: 3.25%;
    bottom: 20px;
    .blob {
      left: 5.25%;
    }
  }
}

@media (min-width: 1400px) {
  .car {
    .blob {
      left: 5.25%;
      top: 7.5%;
    }
  }
}

@include media-breakpoint-up(xxl) {
  .car {
    left: 3.25%;
    padding-right: 18rem;
  }
  .content {
    width: 28%;
    margin-right: 5rem;
  }
}

@media (min-width: 1550px) {
  .car {
    left: 3%;
    padding-right: 22rem;
  }
  .content {
    width: 24%;
    margin-right: 5rem;
  }
}
