@import "/styles/variables.scss";

.footer {
  background-color: $black;
  color: $white;
  display: flex;
  text-align: left;
  position: relative;
  z-index: 1;
}

.footerContent {
  margin-top: 70px;
}

.footerBottom {
  margin-top: rem(35px);
  margin-bottom: rem(65px);
}

.footerTitle {
  font-size: $fontSize_lgx;
  font-weight: $fontWeightBold;
  padding-bottom: rem(11px);
}

.footerLink {
  margin-top: rem(18px);
}

.appStoreLink {
  cursor: pointer;
  margin-right: 12px;
}

.appStoreWrapper {
  padding-top: rem(60px);
  display: flex;
}

.appStoreBtn {
  border: none;
  width: auto;
  height: auto;
  background-color: transparent;
}

.link_text {
  font-size: 15px;
  font-weight: $fontWeightNormal;
}

.socialMediaGroup {
  display: flex;
  margin-top: rem(13px);
}

.socialMeida {
  margin-right: 10px;
  &:hover {
    opacity: 75%;
  }
}

.footerBottom {
  display: flex;
  justify-content: space-between;
  color: $purpleRain;
  font-size: $fontSize_xs;
  font-weight: $fontWeightNormal;
}

.footerBottomLeft,
.footerBottomRight {
  display: flex;
  .footerBottomItem {
    &:not(:first-child) {
      padding-left: 15px;
    }
    &:not(:last-child) {
      padding-right: 15px;
    }
  }
}

@include media-breakpoint-down(md) {
  .column {
    margin-top: 40px;
    .footerLink,
    .socialMediaGroup {
      margin-top: 15px;
    }
  }

  .appStoreWrapper {
    padding-top: rem(50px);
  }

  .footerBottom,
  .footerBottomLeft,
  .footerBottomRight {
    flex-direction: column;
  }

  .footerBottom {
    margin-bottom: rem(120px);
  }

  .footerBottomLeft,
  .footerBottomRight {
    .footerBottomItem {
      &:not(:first-child) {
        padding: 0;
      }
      margin-top: 10px;
    }
  }
}
