@import "/styles/variables.scss";

.main {
  padding-left: calc(50% - 720px);
  margin: auto;
  overflow: hidden;
  background-color: #000000;
  color: $white;
  padding-bottom: 60px;
  padding-top: 60px;
  transform: scale(1);
  z-index: 1;
  position: relative;
}

.container {
  align-items: center;
  display: flex;
}

.testimonialsContent {
  padding-bottom: 70px;
}

.heading {
  color: $pink;
  font-size: rem(42px);
  font-weight: $fontWeightBold;
  margin-bottom: 24px;
  letter-spacing: -0.035em;
  line-height: rem(44px);
}

.subheading {
  font-size: rem(15px);
  line-height: rem(22px);
  padding-bottom: 40px;
}

.carousel {
  background-color: $pink;
  height: 500px; // TEMP
}

.left_col {
  padding-right: 0;
  padding-left: 0;
}

.right_col {
  padding-right: 0;
  padding-left: 0;
}

.text_div {
  padding-left: 120px;
  max-width: 520px;
}

.headline {
  color: $pink;
  font-weight: 700;
  font-size: rem(60px);
  line-height: rem(60px);
  letter-spacing: -0.035em;
  margin-bottom: 25px;
}

.ratings_div {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.stars_container {
  padding-right: 6px;
}

.rating {
  padding-right: 6px;
}

.subheadline {
  font-size: rem(15px);
  line-height: rem(22px);
  max-width: 365px;
}

.features_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  padding: 90px 120px 0;
}

.logo_wrapper {
  position: relative;
  width: 12%;
  height: 26px;
}

.featureLogo {
  opacity: 0.65;
}

.dot_la_logo {
  width: 3vw;
  max-width: 43px;
}

.ktla_logo {
  width: 7vw;
  max-width: 101px;
}

.iheart_logo {
  width: 5.8vw;
  max-width: 84px;
}

.electrek_logo {
  width: 8.6vw;
  max-width: 123px;
}

.bloomberg_logo {
  margin-bottom: -8px;
}

@media (max-width: 1440px) {
  .main {
    padding-left: 0;
  }
}

@media (max-width: 1280px) {
  .text_div {
    padding-left: 60px;
    padding-right: 40px;
  }
}

@media (max-width: 992px) {
  .main {
    padding: 70px 0;
  }

  .text_div {
    width: 100%;
    margin: auto;
    max-width: 720px;
    padding-left: 40px;
  }

  .headline {
    margin-bottom: 20px;
    max-width: 360px;
  }

  .subheadline {
    margin-bottom: 40px;
  }

  .features_main {
    padding: 60px 60px 0;
    flex-wrap: wrap;
  }

  .logo_wrapper {
    width: 21%;
    margin-bottom: 25px;
    max-width: none;
  }

  .bloomberg_logo {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .right_col {
    padding-left: 0;
  }

  .text_div {
    padding-left: 0;
    max-width: 540px;
  }

  .headline {
    font-size: rem(42px);
    line-height: rem(44px);
    max-width: 450px;
  }

  .subheadline {
    max-width: 450px;
  }

  .features_main {
    width: 100%;
    height: 259px;
    max-width: 576px;
    margin: 0 auto;
    padding: 30px 20px 0 40px;
    flex-direction: column;
    align-items: flex-start;
  }

  .logo_wrapper {
    width: 40%;
    height: 36px;
    margin-bottom: 10px;
  }

  .wsj_logo {
    max-width: 205px;
  }

  .bloomberg_logo {
    max-width: 144px;
  }

  .dot_la_logo {
    max-width: 56px;
  }

  .ktla_logo {
    max-width: 130px;
  }

  .dot_la_logo,
  .ktla_logo {
    order: 1;
  }

  .benzinga_logo {
    order: 2;
    max-width: 195px;
  }

  .la_biz_logo {
    order: 3;
    max-width: 166px;
  }

  .iheart_logo {
    order: 4;
    max-width: 108px;
  }

  .electrek_logo {
    order: 5;
    max-width: 156px;
  }
}

@media screen and (max-width: 576px) {
  .main {
    padding: 60px 0;
  }

  .headline {
    padding-right: 7vw;
  }

  .text_div {
    max-width: unset;
    padding-left: 40px;
  }
}

@media (max-width: 1280px) {
  .text_div {
    padding-left: 60px;
    padding-right: 40px;
  }
}

@media (max-width: 992px) {
  .main {
    padding: 70px 0;
  }

  .text_div {
    width: 100%;
    margin: auto;
    max-width: 720px;
    padding-left: 40px;
  }

  .headline {
    margin-bottom: 20px;
    max-width: 360px;
  }

  .subheadline {
    margin-bottom: 40px;
  }

  .features_main {
    padding: 60px 60px 0;
  }

  .logo_wrapper {
    align-items: center;
    max-height: unset;
    padding: 20px;
  }

  .wider_col {
    min-width: unset;
    margin: 0;
  }

  .last_col {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .right_col {
    padding-left: 0;
  }

  .text_div {
    padding-left: 0;
    max-width: 540px;
  }

  .headline {
    font-size: rem(42px);
    line-height: rem(44px);
    max-width: 261px;
  }

  .subheadline {
    max-width: 295px;
  }

  .features_main {
    width: 100%;
    max-width: 576px;
    margin: 0 auto;
    padding: 30px 40px 10px;
  }

  .logo_wrapper {
    justify-content: flex-start;
    align-items: center;
    max-height: none;
    padding: 20px;
  }
}

@media screen and (max-width: 576px) {
  .main {
    padding: 60px 0;
  }

  .text_div {
    max-width: unset;
    padding-left: 40px;
  }
}

@media (max-width: 1280px) {
  .text_div {
    padding-left: 60px;
    padding-right: 40px;
  }
}

@media (max-width: 992px) {
  .main {
    padding: 70px 0;
  }

  .text_div {
    width: 100%;
    margin: auto;
    max-width: 720px;
    padding-left: 40px;
  }

  .headline {
    margin-bottom: 20px;
    max-width: 360px;
  }

  .subheadline {
    margin-bottom: 40px;
  }

  .features_main {
    padding: 60px 60px 0;
  }

  .logo_wrapper {
    align-items: center;
    max-height: unset;
    padding: 20px;
  }

  .wider_col {
    min-width: unset;
    margin: 0;
  }

  .last_col {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .right_col {
    padding-left: 0;
  }

  .text_div {
    padding-left: 0;
    max-width: 540px;
  }

  .headline {
    font-size: rem(42px);
    line-height: rem(44px);
    max-width: 261px;
  }

  .subheadline {
    max-width: 295px;
  }

  .features_main {
    width: 100%;
    max-width: 576px;
    margin: 0 auto;
    padding: 30px 40px 10px;
  }

  .logo_wrapper {
    justify-content: flex-start;
    align-items: center;
    max-height: none;
    padding: 20px;
  }
}

@media screen and (max-width: 576px) {
  .main {
    padding: 60px 0;
  }

  .text_div {
    max-width: unset;
    padding-left: 40px;
  }
}
