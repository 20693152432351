@import "/styles/variables.scss";

.formLabel {
  font-weight: 500;
  font-size: rem(13px);
  line-height: rem(15px);
  letter-spacing: -0.02em;
  margin-bottom: 7px;
}

.formControl {
  border: 1px solid rgb(21, 22, 30, 0.2);
  border-radius: 5px;
  background-color: transparent;
  padding: 0px 15px;
  width: 100%;
  color: $black;
  font-weight: 500;
  font-size: rem(16px);
  height: 40px;
  letter-spacing: -0.015em;

  &:focus {
    caret-color: $pink;
    outline: 2px solid $pink !important;
    outline-offset: -1px;
  }
}

.formGroup {
  margin-bottom: 25px;
}

.errorMessage {
  color: $red;
  font-size: rem(10px);
  font-weight: 700;
  line-height: 10px;
  margin-top: 5px;
}

@include media-breakpoint-up(md) {
  .formControl {
    font-size: rem(15px);
  }
}
