@import "../../../styles/variables.scss";

.modalDialog {
  max-width: none;
}

.header {
  border: none;
  z-index: 100;
}

.closeButtonWrapper {
  position: absolute;
  right: 0px;
  top: -1rem;
}

.modalContent {
  height: 90vh;
  margin-left: auto;
  margin-right: auto;
}

@include media-breakpoint-up(md) {
  .modalContent {
    height: 45vw;
    width: 80vw;
  }

  .closeButtonWrapper {
    right: -2rem;
  }
}
