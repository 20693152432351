@import "/styles/variables.scss";

.modalContent {
  border-radius: 2px;
}

.modalHeader {
  border-bottom: 0px;

  :global(.btn-close) {
    opacity: 1;
  }
}

.modalBody {
  @include reset-page-content;
  padding-bottom: 70px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 16px;
}

@include media-breakpoint-up(md) {
  .modalContent {
    margin-left: auto;
    margin-right: auto;
    max-width: 375px;
  }

  .modalBody {
    padding-bottom: 40px;
  }
}

@include media-breakpoint-up(lg) {
  .modalContent {
    max-width: 450px;
  }
}
